var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9qiZNH2jA3MrX5i3kuKR4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV || "development";
const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV || "development";

function initializeSentry() {
  /*
      only initialize sentry
      if NODE_ENV is not development environment
      or not already initialized   
  */
  const initializeSentry =
    NODE_ENV !== "development" && !Sentry.getCurrentHub().getClient();

  if (initializeSentry) {
    Sentry.init({
      dsn:
        SENTRY_DSN ||
        "https://d4bb8e8e38b74c18a19e76ad657d8a46@o371978.ingest.sentry.io/5637029",
      environment: SENTRY_ENV,

      beforeSend: (event) => {
        if (!event.extra?.customError) return null;
        return event;
      },
    });
  }
}
initializeSentry();
